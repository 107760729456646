var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Table',{attrs:{"pgLimitGet":_vm.pgLimit,"backendDirectory":"ti/cronjob","busca":{
                    busca: _vm.busca.busca || null,
					banco: _vm.busca.banco || null,
					ativo: _vm.busca.ativo
                },"filter":true,"keydown":_vm.keydown,"headers":{
                    idcronjob: {nome: 'ID', tipo: 'texto'},
                    cronjob: {nome: 'Cronjob', tipo: 'texto'},
                    recorrencia: {nome: 'Recorrência', tipo: 'texto'},
                    banco: {nome: 'Banco', tipo: 'texto'},
					sql: {nome: 'SQL', tipo: 'dialog', class: 'text-center'},
                    ativo: {nome: 'Status', tipo: 'boolean', true: 'Ativo', false: 'Inativo', class: 'text-center'},
                }}},[_c('v-col',{staticClass:"pr-1 d-none d-md-block"},[_vm._v("Cronjobs")]),_c('v-col',{staticClass:"px-1"},[_c('v-select',{attrs:{"label":"Status","outlined":"","clearable":"","dense":"","items":[{id: true, value: 'Ativo'},{id: false, value: 'Inativo'}],"item-text":"value","item-value":"id"},model:{value:(_vm.busca.ativo),callback:function ($$v) {_vm.$set(_vm.busca, "ativo", $$v)},expression:"busca.ativo"}})],1),_c('v-col',{staticClass:"px-1"},[_c('v-select',{attrs:{"label":"Banco","outlined":"","clearable":"","dense":"","items":['GESTOR', 'CISS', 'SENIOR', 'ORACLE']},model:{value:(_vm.busca.banco),callback:function ($$v) {_vm.$set(_vm.busca, "banco", $$v)},expression:"busca.banco"}})],1),_c('v-col',{staticClass:"px-1"},[_c('v-text-field',{attrs:{"label":"Buscar","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.keydown = !_vm.keydown}},model:{value:(_vm.busca.busca),callback:function ($$v) {_vm.$set(_vm.busca, "busca", $$v)},expression:"busca.busca"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('Table',{attrs:{"pgLimitGet":_vm.pgLimit,"backendDirectory":"ti/cronjob-log","busca":{
                    busca: _vm.buscaLog.busca || null,
                    erro: _vm.buscaLog.erro,
					idcronjob: _vm.buscaLog.idcronjob || null,
					duracaoexecucao: _vm.buscaLog.duracaoexecucao || null
                },"filter":true,"keydown":_vm.keydown,"headers":{
                    idlog: {nome: 'ID', tipo: 'texto'},
                    cronjob: {nome: 'Cronjob', tipo: 'texto'},
                    retorno: {nome: 'Retorno', tipo: 'texto'},
                    horarioexecucao: {nome: 'Horário execução', tipo: 'texto'},
                    erro: {nome: 'Erro', tipo: 'boolean', reverte: true},
                    duracaoexecucao: {nome: 'Duração execução', tipo: 'texto'},
                }}},[_c('v-col',{staticClass:"pr-1 d-none d-md-block"},[_vm._v("Log de cronjobs")]),_c('v-col',{staticClass:"px-1"},[_c('v-select',{attrs:{"label":"Cronjob","outlined":"","clearable":"","dense":"","items":_vm.cronjobs.lista,"item-text":"cronjob","item-value":"idcronjob"},model:{value:(_vm.buscaLog.idcronjob),callback:function ($$v) {_vm.$set(_vm.buscaLog, "idcronjob", $$v)},expression:"buscaLog.idcronjob"}})],1),_c('v-col',{staticClass:"px-1"},[_c('v-select',{attrs:{"label":"Duração execução","outlined":"","clearable":"","dense":"","items":[
							{id: 10, value: 'Mais de 10 segundos'},
							{id: 30, value: 'Mais de 30 segundos'},
							{id: 60, value: 'Mais de 1 minuto'},
							{id: 300, value: 'Mais de 5 minutos'},
							{id: 600, value: 'Mais de 10 minutos'} ],"item-text":"value","item-value":"id"},model:{value:(_vm.buscaLog.duracaoexecucao),callback:function ($$v) {_vm.$set(_vm.buscaLog, "duracaoexecucao", $$v)},expression:"buscaLog.duracaoexecucao"}})],1),_c('v-col',{staticClass:"px-1"},[_c('v-select',{attrs:{"label":"Erro","outlined":"","clearable":"","dense":"","items":[{id: true, value: 'Logs com erro'},{id: false, value: 'Logs sem erro'}],"item-text":"value","item-value":"id"},model:{value:(_vm.buscaLog.erro),callback:function ($$v) {_vm.$set(_vm.buscaLog, "erro", $$v)},expression:"buscaLog.erro"}})],1),_c('v-col',{staticClass:"px-1"},[_c('v-text-field',{attrs:{"label":"Buscar","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.keydownLog = !_vm.keydownLog}},model:{value:(_vm.buscaLog.busca),callback:function ($$v) {_vm.$set(_vm.buscaLog, "busca", $$v)},expression:"buscaLog.busca"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<v-row>
		<v-col cols="12">
			<Table
				:pgLimitGet="pgLimit"
				backendDirectory="ti/cronjob"
				:busca="{
                    busca: busca.busca || null,
					banco: busca.banco || null,
					ativo: busca.ativo
                }"
				:filter="true"
				:keydown="keydown"
				:headers="{
                    idcronjob: {nome: 'ID', tipo: 'texto'},
                    cronjob: {nome: 'Cronjob', tipo: 'texto'},
                    recorrencia: {nome: 'Recorrência', tipo: 'texto'},
                    banco: {nome: 'Banco', tipo: 'texto'},
					sql: {nome: 'SQL', tipo: 'dialog', class: 'text-center'},
                    ativo: {nome: 'Status', tipo: 'boolean', true: 'Ativo', false: 'Inativo', class: 'text-center'},
                }"
			>
				<v-col class="pr-1 d-none d-md-block">Cronjobs</v-col>
				<v-col class="px-1">
					<v-select
						label="Status"
						outlined
						clearable
						dense
						:items="[{id: true, value: 'Ativo'},{id: false, value: 'Inativo'}]"
						item-text="value"
						item-value="id"
						v-model="busca.ativo"
					/>
				</v-col>
				<v-col class="px-1">
					<v-select
						label="Banco"
						outlined
						clearable
						dense
						:items="['GESTOR', 'CISS', 'SENIOR', 'ORACLE']"
						v-model="busca.banco"
					/>
				</v-col>
				<v-col class="px-1">
					<v-text-field
						v-model="busca.busca"
						label="Buscar"
						outlined
						dense
						@keydown.enter="keydown = !keydown"
					/>
				</v-col>
			</Table>
		</v-col>
		<v-col cols="12">
			<Table
				:pgLimitGet="pgLimit"
				backendDirectory="ti/cronjob-log"
				:busca="{
                    busca: buscaLog.busca || null,
                    erro: buscaLog.erro,
					idcronjob: buscaLog.idcronjob || null,
					duracaoexecucao: buscaLog.duracaoexecucao || null
                }"
				:filter="true"
				:keydown="keydown"
				:headers="{
                    idlog: {nome: 'ID', tipo: 'texto'},
                    cronjob: {nome: 'Cronjob', tipo: 'texto'},
                    retorno: {nome: 'Retorno', tipo: 'texto'},
                    horarioexecucao: {nome: 'Horário execução', tipo: 'texto'},
                    erro: {nome: 'Erro', tipo: 'boolean', reverte: true},
                    duracaoexecucao: {nome: 'Duração execução', tipo: 'texto'},
                }"
			>
				<v-col class="pr-1 d-none d-md-block">Log de cronjobs</v-col>
				<v-col class="px-1">
					<v-select
						label="Cronjob"
						outlined
						clearable
						dense
						:items="cronjobs.lista"
						item-text="cronjob"
						item-value="idcronjob"
						v-model="buscaLog.idcronjob"
					/>
				</v-col>
				<v-col class="px-1">
					<v-select
						label="Duração execução"
						outlined
						clearable
						dense
						:items="[
							{id: 10, value: 'Mais de 10 segundos'},
							{id: 30, value: 'Mais de 30 segundos'},
							{id: 60, value: 'Mais de 1 minuto'},
							{id: 300, value: 'Mais de 5 minutos'},
							{id: 600, value: 'Mais de 10 minutos'},
						]"
						item-text="value"
						item-value="id"
						v-model="buscaLog.duracaoexecucao"
					/>
				</v-col>
				<v-col class="px-1">
					<v-select
						label="Erro"
						outlined
						clearable
						dense
						:items="[{id: true, value: 'Logs com erro'},{id: false, value: 'Logs sem erro'}]"
						item-text="value"
						item-value="id"
						v-model="buscaLog.erro"
					/>
				</v-col>
				<v-col class="px-1">
					<v-text-field
						v-model="buscaLog.busca"
						label="Buscar"
						outlined
						dense
						@keydown.enter="keydownLog = !keydownLog"
					/>
				</v-col>
			</Table>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Table from "../Widgets/Table";

export default {
	name: "TiCronjob",
	components: { Table },
	data: () => ({
		busca: {},
		buscaLog: {},
		keydown: false,
		keydownLog: false,
		cronjobs: {},
	}),
	computed: {
		...mapState(["pgLimit", "usuario", "backendUrl"]),
	},
	methods: {
		listarCronjobs() {
			return axios
				.post(`${this.backendUrl}ti/cronjob`, {
					limit: 99999,
					offset: 0,
				})
				.then((res) => {
					this.cronjobs = res.data;
				});
		},
	},
	created() {
		this.listarCronjobs();
	},
};
</script>